import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "../redux/user/userSlice";
import { deleteGame } from "../redux/asyncThunk/userActions";
import { useNavigate } from "react-router-dom";

const { hideDialog } = userSlice;

export default function Dialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedGameId = useSelector((state) => state.user?.selectedGameId);
  const userId = useSelector((state) => state.user?.data?._id);

  return (
    <div
      style={{
        position: "absolute",
        width: "300px",
        height: "150px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        top: "40%",
        left: "40%",
        border: "1px solid black",
        borderRadius: "10px",
        gap: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 10px",
        }}
      >
        <CloseIcon
          sx={{ cursor: "pointer" }}
          onClick={() => dispatch(hideDialog())}
        />
      </div>
      <div style={{ textAlign: "center" }}>Подтвердите действие</div>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button onClick={() => dispatch(hideDialog())} size="small">
          Отменить
        </Button>
        <Button
          color="error"
          size="small"
          variant="contained"
          onClick={() => {
            let onSucces;

            onSucces = () => {
              navigate("/main", { replace: true });
            };

            dispatch(
              deleteGame({ userId: userId, id: selectedGameId, onSucces })
            );
          }}
        >
          Удалить
        </Button>
      </div>
    </div>
  );
}
