import { useSelector } from "react-redux";

export default function TelegramChat() {
  const messages = useSelector((state) => state.user.messages);

  return (
    <div className="tgArea">
      {messages?.map((el, i) => {
        return (
          <div className="tgMessage" key={el + i}>
            {el}
          </div>
        );
      })}
    </div>
  );
}
