import { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactFlow, {
  addEdge,
  Background,
  // useNodesState,
  // useEdgesState,
  MiniMap,
  Controls,
  ReactFlowProvider,
  // useReactFlow,
  NodeToolbar,
  Handle,
  Position,
  // getIncomers,
  // getOutgoers,
  // getConnectedEdges,
  applyNodeChanges,
  applyEdgeChanges,
} from "reactflow";
// import { saveGame, loadVideoScene } from "../redux/asyncThunk/userActions";
// import { userSlice } from "../redux/user/userSlice";
import "reactflow/dist/style.css";
import "../index.css";

import { Box, TextField, Button } from "@mui/material";

export function NodeWithToolbar(props) {
  const { data, isConnectable, id, xPos, yPos } = props;

  const selectedGameId = useSelector((state) => state.user?.selectedGameId);

  const [video, setVideo] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [showTextInput, setShowTextInput] = useState(false);

  const dispatch = useDispatch();

  // const handleVideoChange = (e) => {
  //   const file = e.target.files[0];

  //   dispatch(loadVideoScene({ selectedGameId, scene: id, file }));
  //   // setVideo(URL.createObjectURL(file));
  // };

  // console.log(props);
  // const handleAddButtonNode = () => {
  //   const newButtonNode = {
  //     id: (Number(id) + 1 + Math.random()).toString(),
  //     data: { label: buttonText },
  //     position: { x: xPos + 30, y: yPos + 20 },
  //     parentId: id,
  //     sourcePosition: "right",
  //     type: "input",
  //     extent: "parent",
  //   };

  //   dispatch(addNode({ newNode: newButtonNode }));
  // };

  return (
    <>
      <NodeToolbar
        isVisible={data?.forceToolbarVisible || undefined}
        position={data?.toolbarPosition}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "7px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            {showTextInput ? (
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <TextField
                  id="outlined-basic"
                  label="Текст кнопки"
                  variant="outlined"
                  size="small"
                  onChange={(event) => setButtonText(event.target.value)}
                />
                <Button
                  variant="contained"
                  // onClick={() => handleAddButtonNode()}
                >
                  Добавить
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box sx={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                className="toolbar_button"
                onClick={() => setVideo(!video)}
              >
                Добавить видео
              </button>
              {video ? (
                <input
                  type="file"
                  name="videoFile"
                  accept="video/*"
                  // onChange={handleVideoChange}
                />
              ) : null}
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <button
                className="toolbar_button"
                onClick={() => setShowTextInput(!showTextInput)}
              >
                Добавить кнопку
              </button>
            </div>
            <button className="toolbar_button">Удалить</button>
          </Box>
        </Box>
      </NodeToolbar>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div className="">{data?.label}</div>
        <div
          style={{
            height: "90px",
            border: "1px solid white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#2F5478",
          }}
        >
          {data?.hasVideo ? "Видео загружено" : "Загрузите видео"}
        </div>
      </div>
    </>
  );
}
