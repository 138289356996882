import { createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_SERVER_URL || "http://localhost:3000";

export const getGame = createAsyncThunk(
  `${url}/user/getGame`,
  async function (props) {
    const { gameId } = props;

    const response = await fetch(`/game/${gameId}`);

    const game = await response.text();

    return JSON.parse(game);
  }
);

export const addNewGame = createAsyncThunk(
  "user/addNewGame",
  async function (props) {
    const { userId, textProject, onSuccess } = props;

    const response = await fetch(`${url}/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        textProject,
      }),
    });

    const result = await response.text();

    onSuccess?.();

    return JSON.parse(result);
  }
);

export const deleteGame = createAsyncThunk(
  "user/deleteGame",
  async function (props) {
    const { userId, id, onSucces } = props;

    const response = await fetch(`${url}/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
      }),
    });

    if (response) {
      onSucces?.();

      return response.status;
    }
  }
);

export const saveGame = createAsyncThunk(
  "user/saveGame",
  async function (props) {
    const { selectedGameId, nodes, edges } = props;

    const response = await fetch(`${url}/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          selectedGameId,
          nodes,
          edges,
        },
      }),
    });

    const result = await response.text();
    return JSON.parse(result);
  }
);

export const loadVideoScene = createAsyncThunk(
  `${url}/user/addVideoScene`,
  async function (props) {
    const { selectedGameId, file, scene } = props;
    const formData = new FormData();

    formData.append("videoFile", file);

    const response = await fetch(`/upload/${selectedGameId}/${scene}`, {
      method: "POST",
      body: formData,
    });

    const result = await response.text();

    return JSON.parse(result);
  }
);
