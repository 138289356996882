import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { getGamesData } from "../redux/user/userSlice";

export default function Dashbord() {
  const dispatch = useDispatch();
  const [keyValue, setKeyValue] = useState("");

  useEffect(() => {
    dispatch(getGamesData());
  }, [dispatch]);

  const gamesData = useSelector((state) => state.user?.games);

  const generateKey = () => {
    const key = Date.now() * Math.random();

    setKeyValue(key);
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "10px 0" }}
      >
        <h1>Рабочий стол</h1>
      </div>

      {gamesData?.length ? (
        <div style={{ display: "flex", gap: "20px", padding: "20px 20px" }}>
          {gamesData?.map((el) => {
            return (
              <div
                key={el._id}
                style={{
                  border: "2px solid #2F5478",
                  width: "300px",
                  height: "300px",
                }}
              >
                <div style={{ textAlign: "center", padding: "5px 0" }}>
                  {el.title}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      border: "1px solid #2F5478",
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "140px",
                      flexDirection: "column",
                      padding: "3px 3px",
                    }}
                  >
                    <NoPhotographyIcon />
                    <span>Загрузите постер</span>
                  </div>
                </div>

                <div style={{ padding: "5px 3px" }}>
                  <div>
                    <div>Кол-во сцен: {el.nodes.length}</div>
                    <div>Осталось ключей: {el.nodes.length}</div>
                    <div>Кол-во просмотров: {0}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div title="Сгенерировать код доступа">
                      <VpnKeyIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => generateKey()}
                      />
                    </div>
                    <input type="text" disabled value={keyValue} />
                  </div>
                  {/* <div>Настройки проекта</div> */}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <div>
            <div>Вы пока еще не создали свой первый проект</div>
          </div>
        </div>
      )}
    </div>
  );
}
