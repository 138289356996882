import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NodeToolbar } from "reactflow";
import { loadVideoScene } from "../../redux/asyncThunk/userActions";
import { userSlice } from "../../redux/user/userSlice";
import "reactflow/dist/style.css";
import "../index.css";

import { Box, TextField, Button } from "@mui/material";

const { addNode } = userSlice;

export const firstSceneNodes = [
  {
    id: "1-group",
    position: { x: 0, y: 0 },
    type: "group",
    style: {
      backgroundColor: "rgba(45, 157, 188, 0.5)",
      width: 300,
      height: 300,
    },
  },
  {
    id: "1",
    position: { x: 1, y: 1 },
    type: "first-scene-node",
    className: "light",
    style: {
      backgroundColor: "rgba(125, 157, 188, 0.5)",
      width: 298,
      height: 200,
    },
    extent: "parent",
    parentNode: "1-group",
    parentId: "1-group",
  },
];

export function FirstSceneNode() {
  const selectedGameId = useSelector((state) => state.user?.selectedGameId);

  const [video, setVideo] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [showTextInput, setShowTextInput] = useState(false);

  const dispatch = useDispatch();

  const handleVideoChange = (e) => {
    const file = e.target.files[0];

    dispatch(loadVideoScene({ selectedGameId, scene: 1, file }));
    // setVideo(URL.createObjectURL(file));
  };

  const handleAddButtonNode = () => {
    const newButtonNode = {
      id: "1-1",
      data: { label: buttonText },
      position: { x: 10, y: 260 },
      parentId: "1-group",
      sourcePosition: "right",
      type: "input",
      extent: "parent",
      parentNode: "1-group",
    };

    dispatch(addNode({ data: [newButtonNode] }));
  };

  return (
    <>
      <NodeToolbar isVisible={undefined}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            {showTextInput ? (
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <TextField
                  id="outlined-basic"
                  label="Текст кнопки"
                  variant="outlined"
                  size="small"
                  onChange={(event) => setButtonText(event.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={() => handleAddButtonNode()}
                >
                  Добавить
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box sx={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                className="toolbar_button"
                onClick={() => setVideo(!video)}
              >
                Добавить видео
              </button>
              {video ? (
                <input
                  type="file"
                  name="videoFile"
                  accept="video/*"
                  onChange={handleVideoChange}
                />
              ) : null}
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <button
                className="toolbar_button"
                onClick={() => setShowTextInput(!showTextInput)}
              >
                Добавить кнопку
              </button>
            </div>
            <button className="toolbar_button">Удалить</button>
          </Box>
        </Box>
      </NodeToolbar>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div className="">{"Сцена 1"}</div>
        <div
          style={{
            height: "90px",
            border: "1px solid white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#2F5478",
          }}
        >
          Загрузите первую сцену
          {/* {data?.hasVideo ? "Видео загружено" : "Загрузите первую сцену"} */}
        </div>
      </div>
    </>
  );
}
