import { useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactFlow, {
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
  ReactFlowProvider,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
} from "reactflow";
// import { saveGame, loadVideoScene } from "../redux/asyncThunk/userActions";
import { userSlice } from "../redux/user/userSlice";
import { Button } from "@mui/material";
import { NodeWithToolbar } from "./nodes/NodeWithToolBar";
import { FirstSceneNode } from "./nodes/FirstSceneNode";
import "reactflow/dist/style.css";
import "./index.css";

const { showDialog, addNode, addEdges } = userSlice;

const nodeTypes = {
  "node-with-toolbar": NodeWithToolbar,
  "first-scene-node": FirstSceneNode,
};

const Flow = (props) => {
  const { game } = props;

  const dispatch = useDispatch();

  const selectedGameId = useSelector((state) => state.user?.selectedGameId);

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(game?.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(game?.edges);

  useEffect(() => {
    setNodes(game?.nodes);
    setEdges(game?.edges);
  }, [game, setEdges, setNodes]);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        const getId = connectingNodeId.current.split("-");

        const newId = Number(getId[0]) + 1;
        const strID = newId.toString();

        const groupNode = {
          id: `${strID}-group`,
          type: "group",
          position: {
            x: event.clientX,
            y: event.clientY,
          },
          style: {
            backgroundColor: "rgba(45, 157, 188, 0.5)",
            width: 300,
            height: 300,
          },
        };

        const newNode = {
          id: strID,
          data: { label: `Сцена ${strID}` },
          position: {
            x: groupNode.position.x + 1,
            y: groupNode.position.y + 1,
          },
          type: "node-with-toolbar",
          targetPosition: "left",
          className: "light",
          style: {
            backgroundColor: "rgba(125, 157, 188, 0.5)",
            width: 298,
            height: 200,
          },
          extent: "parent",
          parentId: `${strID}-group`,
          parentNode: `${strID}-group`,
        };

        setNodes((nds) => nds.concat([groupNode, newNode]));

        setEdges((eds) =>
          eds.concat({
            id: strID,
            source: connectingNodeId.current,
            target: strID,
            type: "input",
          })
        );
        dispatch(addNode({ data: [groupNode, newNode] }));
        dispatch(addEdges({ data: [edges] }));
      }
    },
    [dispatch, edges, setEdges, setNodes]
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [setEdges, edges, nodes]
  );

  return (
    <div className="wrapper" ref={reactFlowWrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px 10px",
          gap: "10px",
        }}
      >
        {/* <Button variant="contained" size="small" onClick={() => preSave()}>
          Сохранить
        </Button> */}

        {/* <Button
          variant="contained"
          size="small"
          // onClick={() => setEdit(!edit)}
        >
          Настройки проекта
        </Button> */}

        <Button
          variant="contained"
          onClick={() => dispatch(showDialog())}
          color="error"
          size="small"
        >
          Удалить
        </Button>
      </div>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodesDelete={onNodesDelete}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        fitView
        className="react-flow-subflows-example"
        nodeTypes={nodeTypes}
        // selectedGameId={selectedGameId}
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

function Workarea(props) {
  const { game } = props;

  return (
    <ReactFlowProvider>
      <Flow {...props} game={game} />
    </ReactFlowProvider>
  );
}

export default Workarea;
