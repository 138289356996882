import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TelegramChat from "./TelegramChat";
import ClearIcon from "@mui/icons-material/Clear";
import { userSlice } from "../redux/user/userSlice";

const { clearMessages } = userSlice;

export default function TelegramView() {
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState(false);
  const [messages, setMessages] = useState([]);
  const [game, setGame] = useState();

  const selectedGameId = useSelector((state) => state.user?.selectedGameId);
  const games = useSelector((state) => state.user?.data?.games);

  const handleActive = () => {
    setActiveButton(!activeButton);
  };

  const handleLeave = () => {
    setActiveButton(activeButton);
  };

  const handleRunGame = () => {
    console.log(selectedGameId);
    const findGame = games.find((el) => el._id === selectedGameId);

    setGame(findGame);
    // console.log(findGame);
  };

  const activeStyle = {
    backgroundColor: "white",
    color: "#272542",
  };

  return (
    <div className="tgContainer">
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ padding: "20px 20px", color: "white" }}>
            <ClearIcon
              sx={{ cursor: "pointer" }}
              onClick={() => dispatch(clearMessages())}
            />
          </div>
        </div>
      </div>

      <TelegramChat messages={messages} setMessages={setMessages} />

      <div
        style={activeButton ? activeStyle : null}
        className="tgButton"
        onMouseDown={handleActive}
        onMouseUp={handleActive}
        onMouseLeave={handleLeave}
        onClick={handleRunGame}
      >
        <div>Запустить</div>
      </div>
    </div>
  );
}
