import { useSelector } from "react-redux";
import TelegramView from "../components/TelegramView";
import { Routes, Route } from "react-router-dom";
import Game from "../components/Game";
import Dashbord from "../components/Dashbord";
import NewGame from "../components/NewGame";
import Dialog from "../components/Dialog";
import SideMenu from "../components/SideMenu";

export default function PersonalPage() {
  const showDialog = useSelector((state) => state.user.showDialog);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <SideMenu />

      <div style={{ width: "75%" }}>
        <Routes>
          <Route path="/" element={<Dashbord />} />
          <Route path="/new" element={<NewGame />} />
          <Route path="/:id" element={<Game />} />
        </Routes>
      </div>

      <TelegramView />

      {showDialog ? <Dialog /> : null}
    </div>
  );
}
