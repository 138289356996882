import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import Workarea from "./Workarea";
import { getGame } from "../redux/asyncThunk/userActions";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { userSlice } from "../redux/user/userSlice";
import { firstSceneNodes } from "./nodes/FirstSceneNode";

import { saveGame } from "../redux/asyncThunk/userActions";

const { addNode } = userSlice;

export default function Game() {
  const [gameData, setGameData] = useState(null);

  const dispatch = useDispatch();

  const selectedGameId = useSelector((state) => state.user?.selectedGameId);
  const status = useSelector((state) => state.user?.status);
  const game = useSelector((state) => state.user.game);

  useEffect(() => {
    if (selectedGameId) {
      dispatch(getGame({ gameId: selectedGameId }));
    }
  }, [dispatch, selectedGameId]);

  useEffect(() => {
    if (game) {
      if (game.nodes.length < 1) {
        dispatch(addNode({ data: [...firstSceneNodes] }));
      } else {
        setGameData(game);
      }
    }
  }, [dispatch, game]);

  useEffect(() => {
    dispatch(
      saveGame({ selectedGameId, nodes: game?.nodes, edges: game?.edges })
    );
  }, [dispatch, game?.edges, game?.nodes, selectedGameId]);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1>{gameData?.title}</h1>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div>Лимит доступов: {findGame.limit}</div>
          <div>Количество предоставленных доступов: {0}</div>
          <div>
            <Button variant="contained" size="small">
              Сгенерировать код доступа
            </Button>
          </div>
        </div> */}
      </div>

      <div style={{ width: "inherit", height: "85vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px 0",
          }}
        >
          <div>
            <Divider sx={{ width: "300px" }} />
          </div>
        </div>

        {status === "loading" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "95vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>{gameData ? <Workarea game={gameData} /> : null}</>
        )}
      </div>
    </div>
  );
}
