import "./App.css";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import MainPage from "./pages/MainPage";
import PersonalPage from "./pages/PersonalPage";
import { Routes, Route, Link, Outlet, useNavigate } from "react-router-dom";
import { loadAuth } from "./redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { userSlice } from "./redux/user/userSlice";
import TelegramLoginButton from "./components/TelegramAuth";

const { exitHandle, resetSelectedProject } = userSlice;

function App() {
  const user = useSelector((state) => state.user.data);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/", { replace: true });
    }
  }, [navigate, user]);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<MainPage />} />
        <Route path="/main/*" element={<PersonalPage />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  const handleAuth = (user) => {
    console.log("User info:", user);
    // Отправьте данные пользователя на ваш сервер для обработки
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  return (
    <div>
      <header className="header">
        <div style={{ width: "10%", textAlign: "center" }}>
          <Link
            to={user ? "/main" : "/"}
            onClick={() => dispatch(resetSelectedProject())}
            sx={{ textDecoration: "none" }}
          >
            <span
              style={{
                cursor: "pointer",
                textDecoration: "none",
                color: "white",
                fontWeight: "bolder",
                fontSize: "18px",
              }}
            >
              MoGaMe
            </span>
          </Link>
        </div>

        <div
          style={{ width: "10%", display: "block", justifyContent: "center" }}
        >
          {user?.tgId ? (
            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <div
                style={{ color: "white", fontWeight: "bold", fontSize: "16px" }}
              >
                {user.tgId}
              </div>
              <LogoutIcon
                sx={{ cursor: "pointer", color: "white" }}
                onClick={() => dispatch(exitHandle())}
              />
            </div>
          ) : (
            <>
              <Link to={"/main"}>
                <Button
                  sx={{ bgcolor: "white" }}
                  variant="outlined"
                  onClick={() => dispatch(loadAuth())}
                >
                  Войти
                </Button>
              </Link>

              <TelegramLoginButton
                // botName={process.env.REACT_APP_BOT_NAME}
                botName={"@RR_232_test_bot"}
                // redirectUri={process.env.REACT_APP_SERVER_URL}
                redirectUri={"https://club-lab.ru:3004/auth"}
                onAuth={handleAuth}
              />
            </>
          )}
        </div>
      </header>

      <Outlet />
    </div>
  );
}

export default App;
