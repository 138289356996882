import React, { useEffect } from "react";

const TelegramLoginButton = ({ botName, redirectUri, onAuth }) => {
  console.log(botName, redirectUri, onAuth);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?14";
    script.setAttribute("data-telegram-login", botName);
    script.setAttribute("data-size", "large");
    script.setAttribute("data-radius", "5");
    script.setAttribute("data-request-access", "write");
    script.setAttribute("data-auth-url", redirectUri);
    script.setAttribute("data-onauth", "onTelegramAuth(user)");
    script.async = true;
    document.body.appendChild(script);

    window.onTelegramAuth = (user) => {
      onAuth(user);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [botName, redirectUri, onAuth]);

  return <div id="telegram-login-button"></div>;
};

export default TelegramLoginButton;
