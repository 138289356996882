import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import { addNewGame } from "../redux/asyncThunk/userActions";
import { getGamesData } from "../redux/user/userSlice";

export default function NewGame() {
  const dispatch = useDispatch();
  const games = useSelector((state) => state.user?.games);
  const userId = useSelector((state) => state.user?.data?.tgId);

  const [textProject, setTextProject] = useState(
    `Новый проект-${games?.length + 1}`
  );

  return (
    <div style={{ padding: "10px 10px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h2>{textProject}</h2>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <div>Название проекта:</div>
            <TextField
              value={textProject}
              size="small"
              onChange={(ev) => setTextProject(ev.target.value)}
            />
          </div>

          <div>Лимит доступов: 25</div>
        </div>
        <div>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              let onSuccess;

              onSuccess = () => {
                dispatch(getGamesData());
                // navigate("/main", { replace: true });
              };

              dispatch(addNewGame({ userId, textProject, onSuccess }));
            }}
          >
            Создать
          </Button>
        </div>
      </div>
    </div>
  );
}
